(function( $ ) {
    $.fn.ellipsis = function(max_height) {
        this.each(function() {
            const $this = $(this);
            const original_text = $this.text();
            let text = original_text;
            shorten();
            setTimeout(() => {
                text = original_text;
                $this.text(text);
                shorten();
            }, 200);
            $(window).resize(() => {
                text = original_text;
                $this.text(text);
                shorten();
            });

            function shorten() {
                while ($this.height() > max_height) {
                    text = text.substr(0, text.length - 4);
                    text = text + '...';
                    $this.text(text);
                }
            }
        });
        return this;
    };
}( jQuery ));

(($) => {
    const $elements = $('.news-section-card__title');
    $elements.css('opacity', 0);
    $(document).ready(function () {
        $elements.ellipsis(115);
    });
    $elements.css('opacity', 1);
})(jQuery);
